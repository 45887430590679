<script lang="ts">
    import {isNumber} from '../../utilities';
    import type {Filters} from "./EventSearch";

    /**
     * the selected filter options
     */
    export let selectedOptions: Filters;

    const namePrefix: string = 'tx_solr[filter]';

    /**
     *
     * @param {string} filterName
     * @param {number} [index]
     * @returns {string}
     */
    function name(filterName, index) {
        let name = `${filterName}`;

        if (isNumber(index)) {
            name = `${name}-${index}`;
        }

        return namePrefix + '[' + name + ']';
    }
</script>

{#each Object.entries(selectedOptions) as [filterName, filterOptions] (filterName)}
    {#each filterOptions as option,index (option.key)}
        {#if filterOptions.length === 1}
            <!-- single selection option -->
            <input
                type="hidden"
                name="{name(filterName)}"
                value="{filterName}:{option.value}"
            />
        {:else}
            <!-- multiple selection options -->
            <input
                type="hidden"
                name="{name(filterName, index)}"
                value="{filterName}:{option.value}"
            />
        {/if}
    {/each}
{/each}

<!-- if a zone is selected render the "Bundesweit" zone filter to include all online seminars (e.g. webinar ...) -->
{#if selectedOptions.zone && selectedOptions.zone.length > 0}
    <input
        type="hidden"
        name="{namePrefix}[zone-Bundesweit]"
        value="zone:Bundesweit"
    />
{/if}

<!-- tx_solr[sort]=when+asc -->
{#if (selectedOptions.zone && selectedOptions.zone.length > 0) ||
     (selectedOptions.topic && selectedOptions.topic.length > 0) ||
     (selectedOptions.when && selectedOptions.when.length > 0) ||
     (selectedOptions.semtyp && selectedOptions.semtyp.length > 0)}
    <input
        type="hidden"
        name="tx_solr[sort]"
        value="when asc"
    />
{/if}