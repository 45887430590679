<script lang="ts">
    import {
        getDaysInMonth,
        getISODay,
        setDate,
        setISODay,
        getISOWeek,
        isSameDay,
        getMonth,
        getYear,
        isWithinInterval,
        isPast
    } from "date-fns";
    import { createEventDispatcher } from "svelte"; 
    import {
        nextMonth as _nextMonth,
        prevMonth as _prevMonth,
        format
    } from "../../date-utilities";
    import CalendarCell from './CalendarCell.svelte';

    const dispatch = createEventDispatcher();

    export let start: Date;
    export let end: Date;
    export let month: Date;
    export let showNextMonthButton: boolean;
    export let showPrevMonthButton: boolean;
    export let disabled: boolean = false;

    $: monthName = format(month, 'MMMM');
    $: prevMonth = _prevMonth(month);
    $: nextMonth = _nextMonth(month);
    $: year = getYear(month);

    $: dateAtTableCell = (rowIdx, colIdx) => {
        const cellIdx = colIdx + 2 + ((rowIdx) * 7) - getISODay(month);
        let dateAtTableCell: Date;
        if (cellIdx < 0) {
            // date from previous month
            dateAtTableCell = setDate(prevMonth, getDaysInMonth(prevMonth)+cellIdx);
        } else if (cellIdx > getDaysInMonth(month)) {
            // date from next month
            dateAtTableCell = setDate(nextMonth, cellIdx-getDaysInMonth(month));
        } else {
            // date from current month
            dateAtTableCell = new Date(year, getMonth(month), cellIdx);
        }

        dateAtTableCell.setHours(23, 59, 59);
        return dateAtTableCell;
    };

    $: weekAtTableRow = (rowIdx) => getISOWeek(dateAtTableCell(rowIdx, 0));

    /**
     *
     * @param colIdx
     */
    function getDaynameShort(colIdx: number): string {
        return format(setISODay(new Date(), colIdx+1), 'EEEEEE');
    }

    /**
     * 
     */
    function handlePrevMonthButtonClick() {
        dispatch('prevMonth');
    }

    /**
     * 
     */
    function handleNextMonthButtonClick() {
        dispatch('nextMonth');
    }
</script>

<div 
    class="vhw-datepicker__calendar"
    class:prev-month-visible={showPrevMonthButton}
    class:next-month-visible={showNextMonthButton}
>
    {#if showPrevMonthButton}
        <button 
            type="button"
            class="vhw-datepicker__prev-month-button" 
            on:click={handlePrevMonthButtonClick}
        >
            <span class="sr-only">Vorheriger Monat</span>
        </button>
    {/if}

    {#if showNextMonthButton}
        <button 
            type="button"
            class="vhw-datepicker__next-month-button" 
            on:click={handleNextMonthButtonClick}
        >
            <span class="sr-only">Nächster Monat</span>
        </button>
    {/if}

    <table>
        <caption>{monthName} {year}</caption>
        <thead>
            <tr>
                <th></th>
                {#each Array(7) as _, colIdx (colIdx)}
                    <th>{getDaynameShort(colIdx)}</th>
                {/each}
            </tr>
        </thead>
        <tbody>
            {#each new Array(6) as _, rowIdx (rowIdx)}
                <tr>
                    <td class="vhw-datepicker__week-number">{weekAtTableRow(rowIdx)}</td>
                    {#each new Array(7) as _, colIdx (colIdx)}
                        {@const date = dateAtTableCell(rowIdx, colIdx)}
                        <td>
                            <CalendarCell 
                                date={date}
                                notInMonth={getMonth(month) !== getMonth(date)}
                                isSelected={start && isSameDay(start, date) || end && isSameDay(end, date)}
                                isInRange={start && end && isWithinInterval(date, {start, end})}
                                isStart={start && isSameDay(start, date)}
                                isEnd={end && isSameDay(end, date)}
                                isPast={isPast(date)}
                                {disabled}
                                on:select
                            />
                        </td>
                    {/each}
                </tr>
            {/each}
        </tbody>
    </table>
</div>

<style lang="less">
    @import "packages/dreipc_vhw/Resources/Public/StyleSheet/_globals";

    .vhw-datepicker__calendar {
        position: relative;

        table {
            width: 100%;
            border-spacing: 0 2px;
    
            caption {
                font-weight: bold;
                text-align: left;
            }
    
            th,
            td {
                text-align: center;
                width: 26px;
                height: 26px;
                line-height: 26px;
            }
        }

        &.prev-month-visible {
            table caption {
                padding-left: 28px;
            }
        }

        &.next-month-visible {
            table caption {
                padding-right: 28px;
            }
        }
    }

    .vhw-datepicker__prev-month-button,
    .vhw-datepicker__next-month-button {
        appearance: none;
        background: transparent;
        border: none;
        position: absolute;
        top: 0;

        span {
            .mx-invisible();
        }

        &:after {
            display: inline-block;
            color: @c-primary-1;
            font-family: 'Iconfont' !important;
            font-size: 16px;
            font-weight: bold;
            line-height: 24px;
            height: 24px;
        }
    }

    .vhw-datepicker__prev-month-button {
        left: 0;

        &:after {
            content: '<';
        }
    }

    .vhw-datepicker__next-month-button {
        right: 0;

        &:after {
            content: '>'
        }
    }

    .vhw-datepicker__week-number {
        color: @c-primary-1;
        opacity: 0.5;
        font-size: 12px;
    }
</style>