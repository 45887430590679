<script lang="ts">
    import { createEventDispatcher } from "svelte";

    export let type: 'checkbox' | 'radio' = 'checkbox';
    export let id: string;
    export let name: string = undefined;
    export let value: string = '';
    export let disabled: boolean = false;
    export let label: string = '';

    const dispatch = createEventDispatcher();

    let element: HTMLInputElement;

    /**
     * if it's a checkbox the group value is an array otherwise it's a string
     */
    export let group: string[] | string = type === 'checkbox' ? [] : '';

    /**
     * automatically compute the checked value
     */
    $: checked = type === 'checkbox' && group.includes(value) || group === value;

    /**
     *
     * @param event
     */
    function handleChange(event: Event) {
        const {value, checked} = event.target as HTMLInputElement;
        if (type === 'checkbox') {
            if (checked) {
                group = [...group, value];
            } else {
                group = group.filter(item => item !== value);
            }
        } else {
            group = value;
        }
    }

    /**
     * programatically trigger a click to propagate the click
     */
    function handleClick() {
        element.click(); 
        element.focus();
    }
</script>

{#if type === 'checkbox'}
    <input
        class="custom"
        type="checkbox"
        {id}
        {name}
        {value}
        {disabled}
        {checked}
        on:change={handleChange}
        on:click
        bind:this={element}
    />
{:else if type === 'radio'}
    <input
        class="custom"
        type="radio"
        {id}
        {name}
        {value}
        {disabled}
        {checked}
        on:change={handleChange}
        on:click
        bind:this={element}
    />
{/if}

<label 
    for="{id}"
    on:click|preventDefault={handleClick}
>
    <slot>{label}</slot>
</label>
