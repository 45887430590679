<script lang="ts">
    import Filterbox from '../Filterbox/Filterbox.svelte';
    import {
        selectionStore,
        filtersStore,
        storeApi,
        loadingStore,
        timespanStore,
    } from './Store';
    import HiddenFields from '../HiddenFileds/HiddenFields.svelte';
    import {writable} from 'svelte/store';
    import {copyObject, equals} from '../../utilities';
    import Datepicker from "../Datepicker/Datepicker.svelte";
    import {format} from 'date-fns';
    import type {Writable} from 'svelte/store';
    import type {FiltersSelection, Timespan} from "./EventSearch";
    import type {DatepickerChangeEvent} from "../Datepicker/Datepicker";
    import type {FilterboxOption} from "../Filterbox/Filterbox";

    const DATE_FORMAT = 'dd.MM.yyyy';

    /**
     * A store that contains the selection of the filterboxes. Initially creates a copy from the event search store
     */
    const selectedKeys: Writable<FiltersSelection> = writable(copyObject($selectionStore.selectedKeys));

    /**
     *
     */
    let datepickerOpen: boolean = false;
    let whenOpen: boolean = false;

    /**
     * handle changes in the selection store
     * @param selectedKeys
     */
    async function handleSelection(selectedKeys) {
        if (equals(selectedKeys, $selectionStore.selectedKeys)) {
            // nothing has changed
            return;
        }

        // Ignore custom option of the when filter.
        if (
            selectedKeys.when !== $selectionStore.selectedKeys.when &&
            selectedKeys.when === 'custom'
        ) {
            return;
        }

        // pass the selection to the event search store
        storeApi.filters.updateSelection(selectedKeys);
    }

    /**
     * handle datepicker change
     * @param event
     */
    function handleDatepicker(event: DatepickerChangeEvent) {
        whenOpen = false;
    
        const timespan = event.detail;
        
        if(equals(timespan, $timespanStore.custom)) {
            // nothing has changed
            return;
        }

        storeApi.filters.updateCustomTimespan(event.detail);
        storeApi.filters.unselectFilter('when');
        storeApi.filters.setSelected({
            filter: 'when',
            key: 'custom',
            selected: true,
        });
    }

    /**
     * on datepicker reset
     */
    function handleDatepickerReset() {
        $selectedKeys.when = '';
    }

    /**
     * opens the datepicker on click
     * @param event
     */
    function handleWhenClick(event: Event) {
        const target = event.target as HTMLInputElement;
        if (target.value === 'custom') {
            event.stopPropagation();
            event.preventDefault();

            datepickerOpen = true;
        }
    }

    /**
     * Joins the selected options with a comma or returns the default label
     * @param selectedOptions
     * @param defaultLabel
     */
    function getLabel(selectedOptions: FilterboxOption[], defaultLabel: string): string {
        return selectedOptions
                .map((option) => option.label)
                .join(', ')
            || defaultLabel;
    }


    /**
     * automatically react to changes in the selection store
     * @see https://svelte.dev/docs#component-format-script-3-$-marks-a-statement-as-reactive
     */
    $: handleSelection($selectedKeys);

    /**
     * automatically react to selection changes in the event search store (e.g. on reset). Creates a copy
     */
    $: $selectedKeys = copyObject($selectionStore.selectedKeys);

</script>

<!-- topic -->
<Filterbox
    options={$filtersStore.filters.topic ?? []}
    name="topic"
    title="Thema"
    label="{getLabel($selectionStore.selectedOptions.topic ?? [], 'Thema')}"
    multiple
    bind:selection={$selectedKeys.topic}
    disabled={$loadingStore}
/>

<!-- when -->
<Filterbox
    options={$filtersStore.filters.when ?? []}
    name="when"
    title="Zeitraum"
    label="{$timespanStore.selected
            ? `${format($timespanStore.selected.start, DATE_FORMAT)} - ${format($timespanStore.selected.end, DATE_FORMAT)}`
            : 'Zeitraum'}"
    bind:selection={$selectedKeys.when}
    bind:open={whenOpen}
    disabled={$loadingStore}
    on:click={handleWhenClick}
>
    <div id="event-search__datepicker" hidden={!datepickerOpen}>
        <button
                type="button"
                id="event-search__close-datepicker"
                title="Datepicker schließen"
                on:click={() => datepickerOpen = false}
        ></button>

        <Datepicker
            title="Zeitraum"
            start={$timespanStore.custom?.start}
            end={$timespanStore.custom?.end}
            disabled={$loadingStore}
            bind:open={datepickerOpen}
            on:change={handleDatepicker}
            on:reset={handleDatepickerReset}
        />
    </div>
</Filterbox>

<!-- semtyp -->
<Filterbox
    options={$filtersStore.filters.semtyp ?? []}
    name="semtyp"
    title="Veranstaltungs-Typ"
    label="{getLabel($selectionStore.selectedOptions.semtyp ?? [], 'Veranstaltungs-Typ')}"
    multiple
    bind:selection={$selectedKeys.semtyp}
    disabled={$loadingStore}
/>

<!-- zone -->
<Filterbox
    options={$filtersStore.filters.zone ?? []}
    name="zone"
    title="Bundesland"
    label="{getLabel($selectionStore.selectedOptions.zone ?? [], 'Bundesland')}"
    multiple
    bind:selection={$selectedKeys.zone}
    disabled={$loadingStore}
/>

<!-- hidden fields for solr -->
<HiddenFields
    selectedOptions={$selectionStore.selectedOptions}
/>

<style lang="less">
    @import "packages/dreipc_vhw/Resources/Public/StyleSheet/_globals";

    #event-search__close-datepicker {
        display: none;
    }

    #event-search__datepicker {
        .mx-shadow-md();
        box-sizing: border-box;
        position: absolute;
        top: -16px;
        left: 10px;
        z-index: 10;
        background-color: @c-white;
        border-radius: 8px;
        border: none;
        padding: 26px;
        width: max-content;
    }

    // ***********************************************************************
    // MEDIA QUERY: tablet portrait
    // ***********************************************************************
    @media screen and (max-width: 985px) {
        #event-search__datepicker {
            border-radius: 0;
            bottom: 0;
            height: max-content;
            left: 0;
            position: fixed;
            right: 0;
            top: auto;
            width: 100%;
            z-index: 100;
        }
    }

    // ***********************************************************************
    // MEDIA QUERY: smartphone portrait
    // ***********************************************************************
    @media screen and (max-width: 479px) {
        #event-search__close-datepicker {
            appearance: none;
            background: transparent;
            border: none;
            display: inline-block;
            position: absolute;
            top: 10px;
            right: 10px;
            transform: rotate(45deg);

            &:after {
                content: '+';
                font-family: 'Iconfont' !important;
                color: @c-primary-1;
            }
        }

        #event-search__datepicker {
            top: @stickyHeaderHeight;
            overflow-y: auto;
            height: auto;
        }
    }
</style>