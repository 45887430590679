<script lang="ts">
    import Filterbox from '../Filterbox/Filterbox.svelte';
    import {
        selectionStore,
        filtersStore,
        storeApi,
        loadingStore,
        timespanStore,
    } from './Store';
    import HiddenFields from '../HiddenFileds/HiddenFields.svelte';
    import {writable} from 'svelte/store';
    import {copyObject, equals} from '../../utilities';
    import Datepicker from "../Datepicker/Datepicker.svelte";
    import {format} from 'date-fns';
    import type {Writable} from 'svelte/store';
    import type {FiltersSelection, Timespan} from "./ProjectSearch";
    import type {DatepickerChangeEvent} from "../Datepicker/Datepicker";
    import type {FilterboxOption} from "../Filterbox/Filterbox";

    const DATE_FORMAT = 'dd.MM.yyyy';

    /**
     * A store that contains the selection of the filterboxes. Initially creates a copy from the project search store
     */
    const selectedKeys: Writable<FiltersSelection> = writable(copyObject($selectionStore.selectedKeys));

    /**
     *
     */
    let datepickerOpen: boolean = false;
    let yearOpen: boolean = false;

    /**
     * handle changes in the selection store
     * @param selectedKeys
     */
    async function handleSelection(selectedKeys) {
        if (equals(selectedKeys, $selectionStore.selectedKeys)) {
            // nothing has changed
            return;
        }

        // pass the selection to the project search store
        storeApi.filters.updateSelection(selectedKeys);
    }

    /**
     * handle datepicker change
     * @param event
     */
    function handleDatepicker(event: DatepickerChangeEvent) {
        yearOpen = false;

        const timespan = event.detail;

        if(equals(timespan, $timespanStore.custom)) {
            // nothing has changed
            return;
        }

        storeApi.filters.updateCustomTimespan(event.detail);
        storeApi.filters.unselectFilter('projectstartyear');
        storeApi.filters.setSelected({
            filter: 'projectstartyear',
            key: 'custom',
            selected: true,
        });
    }

    /**
     * on datepicker reset
     */
    function handleDatepickerReset() {
        $selectedKeys.projectstartyear = '';
    }


    /**
     * opens the datepicker on click
     * @param event
     */
    function handleWhenClick(event: Event) {
        const target = event.target as HTMLInputElement;
        if (target.value === 'custom') {
            event.stopPropagation();
            event.preventDefault();

            datepickerOpen = true;
        }
    }

    /**
     * Joins the selected options with a comma or returns the default label
     * @param selectedOptions
     * @param defaultLabel
     */
    function getLabel(selectedOptions: FilterboxOption[], defaultLabel: string): string {
        return selectedOptions
                .map((option) => option.label)
                .join(', ')
            || defaultLabel;
    }

    /**
     * creates the timespan label or returns the default label
     * @param timespan
     */
    function getTimespanLabel(timespan: Timespan): string {
        if ($timespanStore.selected) {
            console.log("start: " + $timespanStore.selected.start);
            console.log("start: " + $timespanStore.selected.end);
        }
        return $timespanStore.selected
            ? `${format($timespanStore.selected.start, DATE_FORMAT)} - ${format($timespanStore.selected.end, DATE_FORMAT)}`
            : 'Zeitraum'
    }

    /**
     * automatically react to changes in the selection store
     * @see https://svelte.dev/docs#component-format-script-3-$-marks-a-statement-as-reactive
     */
    $: handleSelection($selectedKeys);

    /**
     * automatically react to selection changes in the project search store (e.g. on reset). Creates a copy
     */
    $: $selectedKeys = copyObject($selectionStore.selectedKeys);
</script>

<!-- projectthema -->
<Filterbox
    options={$filtersStore.filters.projectthema}
    name="projectthema"
    title="Thema"
    label="{getLabel($selectionStore.selectedOptions.projectthema, 'Thema')}"
    multiple
    bind:selection={$selectedKeys.projectthema}
    disabled={$loadingStore}
/>

<!-- projectstatus -->
<Filterbox
        options={$filtersStore.filters.projectstatus}
        name="projectstatus"
        title="Projektstatus"
        label="{getLabel($selectionStore.selectedOptions.projectstatus, 'Projektstatus')}"
        multiple
        bind:selection={$selectedKeys.projectstatus}
        disabled={$loadingStore}
/>

<!-- projectstartyear -->
<Filterbox
        options={$filtersStore.filters.projectstartyear}
        name="projectstartyear"
        title="Zeitraum"
        label="{getTimespanLabel($timespanStore.selected)}"
        bind:selection={$selectedKeys.projectstartyear}
        bind:open={yearOpen}
        disabled={$loadingStore}
        on:click={handleWhenClick}
>
    <div id="project-search__datepicker" hidden={!datepickerOpen}>
        <button
                type="button"
                id="project-search__close-datepicker"
                title="Datepicker schließen"
                on:click={() => datepickerOpen = false}
        ></button>

        <Datepicker
                title="Zeitraum"
                start={$timespanStore.custom?.start}
                end={$timespanStore.custom?.end}
                disabled={$loadingStore}
                bind:open={datepickerOpen}
                on:change={handleDatepicker}
                on:reset={handleDatepickerReset}
        />
    </div>
</Filterbox>

<!-- hidden fields for solr -->
<HiddenFields
    selectedOptions={$selectionStore.selectedOptions}
/>

<style lang="less">
    @import "packages/dreipc_vhw/Resources/Public/StyleSheet/_globals";

    #project-search__close-datepicker {
        display: none;
    }

    #project-search__datepicker {
        .mx-shadow-md();
        box-sizing: border-box;
        position: absolute;
        top: -16px;
        left: 10px;
        z-index: 10;
        background-color: @c-white;
        border-radius: 8px;
        border: none;
        padding: 26px;
        width: max-content;
    }

    // ***********************************************************************
    // MEDIA QUERY: tablet portrait
    // ***********************************************************************
    @media screen and (max-width: 985px) {
        #project-search__datepicker {
            border-radius: 0;
            bottom: 0;
            height: max-content;
            left: 0;
            position: fixed;
            right: 0;
            top: auto;
            width: 100%;
            z-index: 100;
        }
    }

    // ***********************************************************************
    // MEDIA QUERY: smartphone portrait
    // ***********************************************************************
    @media screen and (max-width: 479px) {
        #project-search__close-datepicker {
            appearance: none;
            background: transparent;
            border: none;
            display: inline-block;
            position: absolute;
            top: 10px;
            right: 10px;
            transform: rotate(45deg);

            &:after {
                content: '+';
                font-family: 'Iconfont' !important;
                color: @c-primary-1;
            }
        }

        #project-search__datepicker {
            top: @stickyHeaderHeight;
            overflow-y: auto;
            height: auto;
        }
    }
</style>