import {tick} from 'svelte';

const $ = jQuery;

/**
 * Calculates the position of the element
 * @param tooltip {HTMLElement}
 * @param target {HTMLElement}
 */
function calculatePosition(tooltip, target) {
    const targetOffset = $(target).offset();
    const targetHeight = $(target).outerHeight();
    const targetWidth = $(target).outerWidth();
    const tooltipHeight = $(tooltip).outerHeight();

    tooltip.style.left = `${targetOffset.left + (targetWidth/2)}px`;
    tooltip.style.top = `${targetOffset.top + targetHeight}px`;

    // if the tooltip is overflowing the document vertically, position it above the target
    const isOverflowing = document.documentElement.scrollHeight > document.documentElement.offsetHeight;
    if(isOverflowing) {
        tooltip.style.top = `${targetOffset.top - tooltipHeight}px`;
        tooltip.classList.add('position-top');
    }
}

/**
 * This action positions the node under the target element.
 * @param tooltip {HTMLElement}
 * @param target {HTMLElement}
 */
export function position(tooltip, target) {
    calculatePosition(tooltip, target);

    function resizeHandler() {
        calculatePosition(tooltip, target);
    }

    window.addEventListener('resize', resizeHandler);

    const resizeObserver = new ResizeObserver(resizeHandler);
    resizeObserver.observe(tooltip);

    return {
        destroy() {
            window.removeEventListener('resize', resizeHandler);
            resizeObserver.disconnect();
        }
    }
}