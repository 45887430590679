<script lang="ts">
    import {isToday} from 'date-fns';
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    export let isStart: boolean;
    export let isEnd: boolean;
    export let date: Date;
    export let isSelected;
    export let isInRange: boolean;
    export let isPast: boolean;
    export let notInMonth: boolean;
    export let disabled: boolean = false;

    function handleClick() {
        dispatch('select', date);
    }
</script>

<span
    class="vhw-datepicker__calendar-cell"
    class:not-in-month={notInMonth}
    class:past={isPast}
    class:selected={isSelected}
    class:in-range={!isSelected && isInRange}
    class:today={isToday(date)}
    class:start={isStart && !isEnd}
    class:end={isEnd && !isStart}
    class:disabled
>
    {#if notInMonth || isPast}
        {date.getDate()}
    {:else}
        <button on:click={handleClick} type="button" {disabled}>
            {date.getDate()}
        </button>
    {/if}
</span>

<style lang="less">
    @import 'packages/dreipc_vhw/Resources/Public/StyleSheet/_globals.less';

    .vhw-datepicker__calendar-cell {
        button {
            appearance: none;
            background: transparent;
            border: none;
            border-radius: 3px;
            color: inherit;
            width: 100%;
            height: 100%;

            &:disabled {
                opacity: 0.5;
                pointer-events: none;
            }

            &:hover,
            &:focus {
                background-color: @c-gray-500;
                color: inherit;
            }
        }

        &.start button {
            border-radius: 3px 0 0 3px;
        }

        &.end button {
            border-radius: 0 3px 3px 0;
        }

        &.selected button {
            background-color: @c-lightblue-50;
            color: @c-white;
        }

        &.in-range button {
            background-color: @c-lightblue-400;
            border-radius: 0;
        }

        &.not-in-month,
        &.past {
            opacity: 0.2;
        }
    }
</style>