<script>
    import {position} from './position';

    /**
     * @type {HTMLElement}
     */
    export let target;

    /**
     * @type {string}
     */
    export let title = '';

    /**
     * @type {string}
     */
    export let content = '';

    /**
     * @type {boolean}
     */
    export let visible = false;

    /**
     * @type {boolean}
     */
    export let loading = false;

    /**
     * @type {HTMLElement}
     */
    export let tooltipElement;
</script>

<style lang="less">
    @import 'packages/dreipc_vhw/Resources/Public/StyleSheet/_globals.less';

    @arrowWidth: 38px;
    @arrowHeight: 14px;
    @arrowOffset: 68px;

    .preview-tooltip {
        box-sizing: border-box;
        max-width: 500px;
        width: 100%;
        padding-top: @arrowHeight;
        position: absolute;
        top: 300px;
    }

    .preview-tooltip__inner {
        background-color: #fff;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
        transform: translateX(-@arrowOffset);
        position: relative;

        // the top arrow
        &:before {
            content: '';
            position: absolute;
            top: -@arrowHeight;
            left: @arrowOffset;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-width: 0 (@arrowWidth/2) @arrowHeight (@arrowWidth/2);
            border-color: transparent transparent @c-lightblue-450 transparent;
            border-style: solid;
        }

        // the bottom arrow
        :global(.position-top) & {
            transform: translateX(-@arrowOffset);

            &:before {
                content: none;
            }

            &:after {
                content: '';
                position: absolute;
                bottom: -@arrowHeight;
                left: @arrowOffset;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-width: @arrowHeight (@arrowWidth/2) 0 (@arrowWidth/2);
                border-color: @c-white transparent transparent transparent;
                border-style: solid;
            }
        }
    }

    .preview-tooltip__header {
        background: @c-lightblue-450;
        padding: 15px 26px;

        h3 {
            margin: 0;
        }
    }

    .preview-tooltip__body {
        padding: 15px 26px;

        :global(h3) {
            color: @c-blue-200;
            font-size: 15px;
        }
    }

    .preview-tooltip__loading {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .preview-tooltip__loading-spinner {
        border-radius: 50%;
        width: 52px;
        height: 52px;
        border: 2px solid #fff;
        border-top-color: @c-blue-200;
        animation: spin 1s linear infinite;
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    // ***********************************************************************
    // MEDIA QUERY: smartphone landscape
    // ***********************************************************************
    @media screen and (max-width: 767px) {
        .preview-tooltip {
            left: 0 !important;
            right: 0 !important;
            padding-left: 26px;
            padding-right: 26px;
            max-width: 100%;
        }

        .preview-tooltip__inner {
            transform: translateX(0);

            &:before,
            &:after {
                left: 50%;
            }
        }
    }
</style>

{#if visible === true}
    <div
        class="preview-tooltip"
        use:position={target}
        bind:this={tooltipElement}
        on:mouseleave={() => visible = false}
        on:click|stopPropagation
    >
        <div class="preview-tooltip__inner">
            <div class="preview-tooltip__header">
                <h3 class="preview-tooltip__title">
                    {title}
                </h3>
            </div>

            <div class="preview-tooltip__body">
                {#if loading === true}
                    <div class="preview-tooltip__loading">
                        <div class="preview-tooltip__loading-spinner"></div>
                    </div>
                {:else}
                    {@html content}
                {/if}
            </div>
        </div>
    </div>
{/if}
