<script lang="ts">
    import {
        parse,
        isValid,
        compareAsc,
        startOfToday,
        differenceInMonths,
        subMonths,
        addMonths,
        startOfMonth
    } from 'date-fns';
    import {format, nextMonth as _nextMonth} from '../../date-utilities';
    import {clickOutside} from '../../actions/click-outside';
    import {createEventDispatcher} from "svelte";
    import {MaskedRange} from 'imask';
    import type IMask from 'imask';
    import {imask} from '@imask/svelte';
    import Calendar from './Calendar.svelte';
    import type {CalendarSelectEvent} from "./Datepicker";

    enum STATE {
        COMPLETED,
        INCOMPLETED
    }

    let state: STATE = STATE.COMPLETED;

    const dispatch = createEventDispatcher();
    const DATE_FORMAT = 'dd.MM.yyyy';
    const imaskConfig: IMask.MaskedDateOptions = {
        mask: Date,
        pattern: DATE_FORMAT,
        lazy: false,
        min: startOfToday(),
        max: new Date(2030, 0, 1),
        format: (date) => format(date, DATE_FORMAT),
        parse: (str) => parse(str, DATE_FORMAT, new Date()),
        blocks: {
            yyyy: {
                mask: MaskedRange,
                from: 1970,
                to: 2030,
                placeholderChar: 'J',
            },
            MM: {
                mask: MaskedRange,
                from: 1,
                to: 12,
                placeholderChar: 'M'
            },
            dd: {
                mask: MaskedRange,
                from: 1,
                to: 31,
                placeholderChar: 'T',
            },
        }
    }

    export let title: string = ''
    export let start: Date;
    export let end: Date;
    export let open: boolean = false;
    export let disabled: boolean = false;

    let month = startOfMonth(start || new Date());
    $: nextMonth = _nextMonth(month);

    /**
     * validates a date
     * @param dateStr
     * @param dateFormat
     */
    function validate(dateStr: string, dateFormat: string): boolean {
        const date = parse(dateStr, dateFormat, new Date());
        return isValid(date) && format(date, dateFormat) === dateStr;
    }

    /**
     * handle change of the start date input field
     * @param event
     */
    function handleStartComplete(event: CustomEvent) {
        const mask = event.detail;
        const value = mask.value;

        if(validate(value, DATE_FORMAT) === false) {
            return;
        }

        const newStart = parse(value, DATE_FORMAT, new Date());

        if(end && compareAsc(newStart, end) === 1) {
            return;
        }

        start = newStart;
        
        dispatchChange();
    }

    /**
     * handle change of the end date input field
     * @param event
     */
    function handleEndComplete(event: CustomEvent) {
        const mask = event.detail;
        const value = mask.value;

        if(validate(value, DATE_FORMAT) === false) {
            return;
        }

        const newEnd = parse(value, DATE_FORMAT, new Date());

        if(start && compareAsc(start, newEnd) === 1) {
            return;
        }

        end = newEnd;
        dispatchChange();
    }

    /**
     * handle the selection of a date in the calendar
     * @param event
     */
    function handleCalendarSelect(event: CalendarSelectEvent) {
        const date = event.detail;

        if (state === STATE.COMPLETED) {
            start = date;
            end = date;
            state = STATE.INCOMPLETED;
        } else if(date < start) {
            start = date;
            state = STATE.COMPLETED;
        } else {
            end = date;
            state = STATE.COMPLETED;
        }

        if (state === STATE.COMPLETED) {
            dispatchChange();
            open = false;
        }
    }

    /**
     *
     */
    function dispatchChange() {
        if (
            start && end && 
            start < end
        ) {
            dispatch('change', {
                start,
                end
            });
        }
    }

    /**
     * 
     */
    function handleReset() {
        open = false;
        dispatch('reset');
    }

    /**
     * subtracts one month from the current month
     */
    function handlePrevMonthButton() {
        month = subMonths(month, 1);
    }

    /**
     * adds one month to the current month
     */
    function handleNextMonthButton() {
        month = addMonths(month, 1);
    }

    $: monthDifference = differenceInMonths(startOfMonth(month), startOfMonth(new Date));
    
</script>

{#if open}
    <div
        class="vhw-datepicker"
        use:clickOutside={() => open = false}
    >
        {#if title}
            <h3>{title}</h3>
        {/if}

        <div class="flex gap-26 flex-nowrap vhw-datepicker__inner">
            <div class="vhw-datepicker__start">
                <div class="vhw-datepicker__input-wrapper">
                    <input
                            type="text"
                            value={start && format(start, DATE_FORMAT) || ''}
                            {disabled}
                            on:complete={handleStartComplete}
                            use:imask={imaskConfig}
                    />
                </div>

                <Calendar 
                    {start}
                    {end}
                    {month}
                    {disabled}
                    showPrevMonthButton={monthDifference > 0}
                    on:select={handleCalendarSelect}
                    on:prevMonth={handlePrevMonthButton}
                />
            </div>
            <div class="vhw-datepicker__end">
                <div class="vhw-datepicker__input-wrapper">
                    <input
                            type="text"
                            value={end && format(end, DATE_FORMAT) || ''}
                            {disabled}
                            on:complete={handleEndComplete}
                            use:imask={imaskConfig}
                    />
                </div>

                <Calendar
                    {start}
                    {end}
                    month={nextMonth}
                    {disabled}
                    showNextMonthButton
                    on:select={handleCalendarSelect}
                    on:nextMonth={handleNextMonthButton}
                />
            </div>
        </div>

        <div class="flex justify-end">
            <button
                type="button"
                class="vhw-datepicker__reset-button link reset"
                on:click={handleReset}
                {disabled}
            >
                Auswahl löschen
            </button>
        </div>
    </div>
{/if}

<style lang="less">
    @import "packages/dreipc_vhw/Resources/Public/StyleSheet/_globals";

    .vhw-datepicker {
        position: relative;
        text-align: left;
        user-select: none;
        width: 100%;
    }

    .vhw-datepicker__start,
    .vhw-datepicker__end {
        flex: 1 1 100%;
    }

    .vhw-datepicker__input-wrapper {
        position: relative;

        &::before {
            content: 'c';
            color: @c-primary-1;
            position: absolute;
            font-family: 'Iconfont' !important;
            left: 10px;
            line-height: 32px;
            height: 32px;
        }

        input {
            color: inherit;
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 13px;
            border-radius: 8px;
            border-color: @c-lightblue-25;
            padding-left: 30px;
            
            &:disabled {
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }


    .vhw-datepicker__reset-button {
        margin-top: 13px;
        margin-left: auto;
    }

    // ***********************************************************************
    // MEDIA QUERY: smartphone portrait
    // ***********************************************************************
    @media screen and (max-width: 479px) {
        .vhw-datepicker__inner {
            flex-direction: column;
        }
    }
</style>