<script lang="ts">
    import {createEventDispatcher, onMount, onDestroy} from 'svelte';
    import CheckboxRadio from '../CheckboxRadio/CheckboxRadio.svelte';
    import {isset} from '../../utilities';
    import {clickOutside} from '../../actions/click-outside';
    import {fade} from 'svelte/transition';
    import {expoOut} from 'svelte/easing';
    import type {FilterboxOption} from "./Filterbox";

    const dispatchEvent = createEventDispatcher();

    /**
     * @typedef {Object} FilterboxChangeEventDetail
     * @property {FilterboxOption[]} selectedOptions
     */

    /**
     * @typedef {CustomEvent.<FilterboxChangeEventDetail>} FilterboxChangeEvent
     */

    /**
     * @type {string}
     */
    export let name: string;

    /**
     * @type {string}
     */
    export let title: string;

    /**
     * @type {string}
     */
    export let label: string;

    /**
     * filter options
     */
    export let options: FilterboxOption[] = [];

    /**
     * if true, the options will be rendered as checkboxes
     * otherwise, the options will be rendered as radio buttons
     * @type {boolean}
     */
    export let multiple = false;

    export let selection: string | string[] = multiple ? [] : '';

    /**
     *
     * @type {boolean}
     */
    export let disabled = false;

    /**
     *
     * @type {boolean}
     */
    export let open = false;

    /**
     * MEDIA QUERY: tablet portrait
     * @type {MediaQueryList}
     */
    const mediaQueryTabletProtrait = window.matchMedia('(max-width: 985px)');

    /**
     * show overlay only on mobile
     * @type {boolean}
     */
    let showOverlay = mediaQueryTabletProtrait.matches;

    /**
     * open/close
     */
    function toggleOpen() {
        open = !open;
    }

    /**
     * close
     */
    function close() {
        if (open) {
            open = false;
        }
    }

    /**
     *
     * @param element
     * @param {duration}
     * @returns {TransitionConfig}
     */
    function animate(element, {duration}) {
        return {
            duration,
            css: (t) => {
                const eased = expoOut(t);

                const transform = mediaQueryTabletProtrait.matches ?
                    `translateY(${100 - (eased * 100)}%)` :
                    `scale(${0.95 + (0.05 * eased)})`;

                return `
                    transform: ${transform};
                    opacity: ${eased};
                `;
            }
        }
    }

    /**
     *
     * @param event
     */
    function handleMediaQuery(event) {
        showOverlay = mediaQueryTabletProtrait.matches;
    }

    /**
     * reset selection
     */
    function handleReset() {
        selection = multiple ? [] : '';
    }

    onMount(() => {
        mediaQueryTabletProtrait.addEventListener('change', handleMediaQuery);
    });

    onDestroy(() => {
        mediaQueryTabletProtrait.removeEventListener('change', handleMediaQuery);
    })
</script>

{#if open && showOverlay}
    <div
        class="filterbox__overlay"
        transition:fade={{duration: 200}}
    ></div>
{/if}

<div
    class="filterbox"
    class:disabled
    use:clickOutside={() => { close(); }}
>
    <button
        id="{name}"
        type="button"
        class="filterbox__toggle-options"
        aria-expanded="{open.toString()}"
        {disabled}
        on:click|stopPropagation={() => !disabled && toggleOpen()}
    >
        {label ?? title}
    </button>

    {#if open}
        <article
            class="filterbox__options"
            in:animate={{duration: 200}}
        >
            <h3>{title}</h3>

            <ul class="reset-list">
                {#each options as option, index (option.key)}
                    <li
                        class="filterbox__option"
                        class:filterbox__option--no-results={isset(option.count) && option.count === 0}
                        data-parentuid="{option.parentIndicator || null}"
                        data-uid="{option.childIndicator || null}"
                    >
                        <CheckboxRadio
                            type="{multiple ? 'checkbox' : 'radio'}"
                            id="filterbox__option-{name}-{index}"
                            checked={option.selected}
                            bind:group={selection}
                            value="{option.key}"
                            {disabled}
                            on:click
                        >
                            <span class="filterbox__option-label">
                                <span>{option.label}</span>
                                {#if isset(option.count) && option.count >= 0}
                                    <span class="filterbox__option-count">({option.count})</span>
                                {/if}
                            </span>
                        </CheckboxRadio>
                    </li>
                {/each}
            </ul>

            <button
                type="button"
                class="filterbox__reset-button link reset"
                on:click={handleReset}
                {disabled}
            >
                Auswahl löschen
            </button>
        </article>
    {/if}

    <slot />
</div>

<style lang="less">
    @import 'packages/dreipc_vhw/Resources/Public/StyleSheet/_globals.less';

    .filterbox__overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0,0.2);
        z-index: 100;
    }

    .filterbox {
        max-width: 100%;
        position: relative;
        width: 100%;
        min-width: 0; // fix grid overflow bug
    }

    .filterbox__toggle-options {
        .mx-shadow();
        appearance: none;
        background-color: @c-white;
        border-radius: 8px;
        border: none;
        color: inherit;
        font-size: 18px;
        max-width: 100%;
        overflow: hidden;
        padding: 6px 36px 6px 25px;
        position: relative;
        text-align: left;
        text-overflow: ellipsis;
        user-select: none;
        white-space: nowrap;
        width: 100%;
        word-break: keep-all;

        &:after {
            color: @c-primary-1;
            content: '=';
            font-family: Iconfont;
            position: absolute;
            right: 13px;
            top: 50%;
            transform: translateY(-50%);
            transition: all 200ms;
            font-size: 15px;
        }

        &[aria-expanded="true"] {
            &:after {
                transform: translateY(-50%) rotate(180deg);
            }
        }

        .disabled & {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    .filterbox__options {
        .mx-shadow-md();
        animation: fadeIn 0.2s ease-in-out;
        background-color: @c-white;
        border-radius: 8px;
        box-sizing: border-box;
        left: 10px;
        overflow: hidden;
        padding: 13px;
        position: absolute;
        top: -16px;
        width: max-content;
        z-index: 10;

        &[aria-hidden="true"] {
            display: none;
        }

        h3 {
            margin-bottom: 13px;
        }
    }

    .filterbox__option {
        + .filterbox__option {
            margin-top: 4px;
        }

        :global(label) {
            display: block;
            width: 100%;
        }

        &[data-parentuid] {
            padding-left: 23px;
        }
    }

    .filterbox__option--no-results {
        opacity: 0.5;
    }

    .filterbox__option-label {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: flex-start;
    }

    .filterbox__option-count {
        margin-left: 26px;
    }

    .filterbox__reset-button {
        margin-top: 13px;

        &[disabled] {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    // ***********************************************************************
    // MEDIA QUERY: tablet portrait
    // ***********************************************************************
    @media screen and (max-width: 985px) {
        .filterbox {
            position: static;
        }

        .filterbox__options {
            border-radius: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            height: max-content;
            left: 0;
            max-height: 40vh;
            position: fixed;
            right: 0;
            top: auto;
            width: 100%;
            z-index: 100;

            ul {
                flex: 1 1 auto;
                overflow-y: auto;
            }
        }
    }
</style>